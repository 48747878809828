import { ReactNode } from "react";
import { useAuth } from "../state/hooks/user.hook";
import { Navigate } from "react-router-dom";
import { useAccess } from "../state/hooks/access.hook";

interface Props {
  children: ReactNode;
  protectedRoute?: boolean;
  reverseProtect?: boolean;
  accessCodeReq?: boolean;
  accessCodeReverse?: boolean;
  suspended?: boolean;
}

const RouteHandler = ({
  children,
  accessCodeReq,
  accessCodeReverse,
  reverseProtect,
  protectedRoute,
  suspended,
}: Props) => {
  const { user } = useAuth();
  const { accessCode } = useAccess();

  if (suspended) {
    // console.log("navigte to suspended");
    return <Navigate to="/suspended" />;
  }

  if (accessCodeReq && !accessCode) {
    return <Navigate to="/" />;
  }

  if (accessCodeReverse && accessCode) {
    return <Navigate to="/login" />;
  }

  if (protectedRoute && !user?.id) {
    return <Navigate to="/login" />;
  }

  if (reverseProtect && user?.id) {
    return <Navigate to="/ibank" />;
  }

  return <>{children}</>;
};

export default RouteHandler;
